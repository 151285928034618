import React from 'react';
import { MainApp } from '@bwb-buildings-mono/ui';

import './app.scss';

import { AppData } from '../data';

export const App = () => {
  return (
    <MainApp {...AppData}/>
  );
};

export default App;
