import React, { useState } from 'react';
import { Layout, Typography, Row, Col, Button } from 'antd';
import { Carousel } from 'antd';
const { Title } = Typography;
const { Content } = Layout;

import { Section, PageSectionProps } from '@bwb-buildings-mono/entities';

interface SectionTextCarouselItem extends Section {
    i?:number;
}

export const SectionTextCarouselItem = (props: SectionTextCarouselItem) => {
    const [showMore, setShowMore] = useState(false);
    const [displayMore, setDisplayMore] = useState(false);

    function doShowMore(){
        setDisplayMore(true);
        setTimeout(() => {
            setShowMore(true);
        }, 100);
    }

    function doHideMore(){
        setShowMore(false);
        setTimeout(() => {
            setDisplayMore(false);
        }, 500);
    }

    return (
        <React.Fragment>
            <Row className="img-section" style={{flexDirection:(props.i % 2 == 1 ? 'row-reverse' : 'row' )}}>
                <Col className="img-sentence" xs={0} md={0}></Col>
                <Col className="img-sentence" xs={24} md={12}>
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', paddingBottom: '12px'}}>
                    { props.sentence }
                    <div>
                    { 
                        props.sentenceMore ?
                            showMore ?
                                <Button onClick={ doHideMore } shape="round" style={{width: '80%', margin: '0 auto'}}>See Less</Button> :
                                <Button onClick={ doShowMore } shape="round" style={{width: '80%', margin: '0 auto'}}>See More</Button>
                            : null
                    }
                    </div>
                    { displayMore && <Row><Col xs={24} md={0}><div style={{ opacity: (showMore ? 1 : 0), width: '100%', overflow: 'hidden', transition: 'opacity .5s ease-in'}}>
                        { props.sentenceMore }
                    </div></Col></Row>}
                </div>
                </Col>
                <Col className="img-images" xs={24} md={12}>
                    <Carousel autoplay style={{maxWidth: '400px', margin: '0 auto'}}>
                        { props.images.map((image, i) => {

                        const images = Array.isArray(image) ? image : [image];

                        if(images.length == 1){
                            return (
                            <div key={'row-' + i}>
                                <div style={{height: '400px', maxWidth: '500px', margin: '0px auto', backgroundImage: `url('${images[0].src}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                </div>
                            </div>
                            )
                        } else {
                            return (
                            <div key={'row-' + i}>
                                <div style={{display: 'flex'}}>
                                <div style={{height: '200px', width: '200px', backgroundImage: `url('${images[0].src}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                </div>
                                <div style={{height: '200px', width: '200px', backgroundImage: `url('${images[1].src}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                <div style={{height: '200px', width: '200px', backgroundImage: `url('${images[2] ? images[2].src : ''}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                </div>
                                <div style={{height: '200px', width: '200px', backgroundImage: `url('${images[3] ? images[3].src : ''}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                </div>
                                </div>
                            </div>
                            )
                        }
                        })}
                    </Carousel>
                    <Row style={{fontSize: '80%'}}>
                        <Col sm={24} xs={0}>
                            Mouse over to stop animation. Mouse off to animate. 
                        </Col>
                    </Row>
                </Col>
                <Col className="img-sentence" xs={0} md={0}></Col>
            </Row>
            
            { displayMore && <Row><Col xs={0} md={24}><div style={{ opacity: (showMore ? 1 : 0), overflow: 'hidden', transition: 'opacity .5s ease-in'}}>
                { props.sentenceMore }
            </div></Col></Row>}
        </React.Fragment>
    )
};

export default SectionTextCarouselItem;
