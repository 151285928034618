import React from 'react';

import { Section } from '@bwb-buildings-mono/entities';

import { Typography } from 'antd';
const { Text, Paragraph } = Typography;


export const AmenitiesSections: Array<Section> = [
    {
      sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Paragraph>Great location close to restaurants, shops & more!</Paragraph>
        <Paragraph>Quiet residential neighborhood - great schools.</Paragraph>
        <Paragraph><Text mark strong>&nbsp;Fitness center&nbsp;</Text> with ellipticals, treadmills, & weights.</Paragraph>
        <Paragraph>Included parking in a secure garage.</Paragraph>
        <Paragraph>Intercom entry and secure access.</Paragraph>
        <Paragraph>Professional <Text mark strong>&nbsp;on-site manager&nbsp;</Text>.</Paragraph>
        <Paragraph>Lush courtyard and <Text mark strong>&nbsp;pool&nbsp;</Text>.</Paragraph>
        <Paragraph>Cat friendly, no dogs.</Paragraph>
      </div>,
      images: [
        {
          src: 'assets/pics/gym_1.jpeg',
          caption: 'Gym',
          thumb: 'assets/thumbs/gym_1.jpeg'
        },
        {
          src: 'assets/pics/patio_1.jpeg',
          caption: 'Sample Unit Patio',
          thumb: 'assets/thumbs/patio_1.jpeg'
        },
        {
          src: 'assets/pics/pool_1.jpeg',
          caption: 'Pool',
          thumb: 'assets/thumbs/pool_1.jpeg'
        },
        {
          src: 'assets/pics/courtyard_1.jpeg',
          caption: 'Courtyard',
          thumb: 'assets/thumbs/courtyard_1.jpeg'
        },
        {
          src: 'assets/pics/manager_1.jpeg',
          caption: 'On Site Manager\'s Office',
          thumb: 'assets/thumbs/manager_1.jpeg'
        },
        {
          src: 'assets/neighborhood_streetview.jpg',
          caption: 'On Site Manager\'s Office',
          thumb: 'assets/neighborhood_streetview.jpg'
        }
      ]
    }
];