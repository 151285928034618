import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
const { Title } = Typography;
const { Content } = Layout;

import { PageSectionProps } from '@bwb-buildings-mono/entities';

interface ImageBlock {
  name: string;
  info: string|any;
  image: string;
  color: string;
  bottom: any;
}

/* eslint-disable-next-line */
export interface SectionImagesProps extends PageSectionProps {
  images:(string|ImageBlock)[];
  imageProps: any;
}

export const SectionImages = (props: SectionImagesProps) => {
  const imageProps = props.imageProps || { xs: 24, md: 12 };

  function renderImageString(image:string){
    return (
      <img style={{maxWidth: '100%'}} src={ image }></img>
    )
  }

  function renderImageObject(image:ImageBlock){
    return (
      <div style={{border: '3px solid ' + image.color, borderRadius: 6 }}>
        <div style={{ backgroundColor: '#F9F9F9', padding: 12, textAlign: 'center'}}>
          <Title level={3}>{ image.name }</Title>
          <div style={{ padding: '0px 18px', fontSize: 16, lineHeight: 1.2, color: 'black'}}>
            { image.info }
          </div>
        </div>
        <img style={{maxWidth: '100%'}} src={ image.image }></img>
        { image.bottom }
      </div>
    )
  }


  return (
    <Content>
      <div ref={props.sectionRef} style={{height: '80px'}}></div>
      <div style={{padding: '12px', textAlign: 'center', marginTop:'72px'}}>
        <Title level={2}>{ props.title }</Title>
      </div>
      <div className="site-layout-content img-sections" style={{padding: 16}}>
        <Row gutter={[32,32]} justify="center">
          {
            props.images.map((image:string|ImageBlock, i) => {
              return (
                <Col key={'image-' + i} {...imageProps} style={{textAlign: 'center'}}>
                  { typeof image === 'string' ? renderImageString(image) : renderImageObject(image) }
                </Col>
              )
            })
          }
        </Row>
      </div>
    </Content>
  );
};

export default SectionImages;
