import React from 'react';
import { Layout, Typography } from 'antd';
const { Content } = Layout;
const { Title } = Typography;

import { PageSectionProps } from '@bwb-buildings-mono/entities';

import './section-title.scss';

/* eslint-disable-next-line */
export interface SectionTitleProps extends PageSectionProps {

}

export const SectionTitle = (props: SectionTitleProps) => {
  return (
    <Content>
      <div ref={props.sectionRef} style={{height: '80px'}}></div>
      <div style={{padding: '12px', textAlign: 'center', marginTop:'72px'}}>
        <Title id={"Contact"} level={2}>{ props.title }</Title>
      </div>
    </Content>
  );
};

export default SectionTitle;
