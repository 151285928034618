import React from 'react';
import { Row, Col, Typography, Carousel } from 'antd';
import { Logo } from '../logo/logo';
import { Tagline } from '../tagline/tagline';

/* eslint-disable-next-line */
export interface AboveFoldProps {
  logo?: any;
  address?: any;
  tagline?:any;
  images:string[];
  flipped?:boolean;
}

export const AboveFold = (props: AboveFoldProps) => {
  return (
    <div>
      { props.logo && <Logo logo={props.logo} address={props.address} /> }
      <div>
        <Row style={{height: 'calc(65vh - 100px)', minHeight: 300, maxHeight: 700, padding: '12px'}}>
          <Col order={(props.flipped ? 2 : 1)} md={12} sm={12} xs={0} style={{paddingRight:(props.flipped ? 0 : 6)}}>
            <div style={{width: '100%', height: '100%', backgroundColor:'red',
            backgroundImage: `url('${props.images[0]}')`,
            backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
          </Col>
          <Col order={(props.flipped ? 1 : 2)} md={12} sm={12} xs={0} style={{paddingRight:(props.flipped ? 6 : 0)}}>
            <Row style={{height: '50%'}}>
              <Col xs={24} md={12} style={{paddingRight:'3px', paddingBottom: '3px'}}>
                <div style={{width: '100%', height: '100%',
                  backgroundImage: `url('${props.images[1]}')`,
                  backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
              </Col>
              <Col xs={0} sm={0} md={12} style={{paddingLeft:'3px', paddingBottom: '3px'}}>
                <div style={{width: '100%', height: '100%',
                  backgroundImage: `url('${props.images[2]}')`,
                  backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
              </Col>
            </Row>
            <Row style={{height: '50%'}}>
              <Col xs={24} md={12} style={{paddingRight:'3px', paddingTop: '3px'}}>
                <div style={{width: '100%', height: '100%',
                  backgroundImage: `url('${props.images[3]}')`,
                  backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
              </Col>
              <Col xs={0} sm={0} md={12} style={{paddingLeft:'3px', paddingTop: '3px'}}>
                <div style={{width: '100%', height: '100%',
                  backgroundImage: `url('${props.images[4]}')`,
                  backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
              </Col>
            </Row>
          </Col>
          <Col md={0} sm={0} xs={24} style={{paddingRight:'6px'}}>
            <Carousel autoplay style={{width: '100%', margin: '0 auto'}}>
              {
                props.images.map((image, i) => {

                  return (
                    <div key={'row-' + i}>
                      <div style={{height: '400px',backgroundImage: `url('${image}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                      </div>
                    </div>
                  );
                })
              }
            </Carousel>
          </Col>
        </Row>
      </div>
      { props.tagline && <Tagline tagline={props.tagline} /> }
    </div>
  );
};

export default AboveFold;
