import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;

import { PageSectionProps } from '@bwb-buildings-mono/entities';

/* eslint-disable-next-line */
export interface HeroImageProps extends PageSectionProps {
  image: string;
  backgroundSize?: string
  height ?: string
}

export const HeroImage = (props: HeroImageProps) => {

  const style = {
    backgroundImage: `url('${props.image}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: props.backgroundSize || 'cover',
    backgroundPosition: 'center center',
    height: props.height || '70vmin',
    width: '100%',
  }

  return (
    <Content>
      <div ref={props.sectionRef}>
        <div style={style}></div>
      </div>
    </Content>
  );
};

export default HeroImage;
