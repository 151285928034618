import React from 'react';

/* eslint-disable-next-line */
export interface LogoProps {
  logo: any;
  name?: string;
  extra?:any;
  address: any;
  phone_number?: any;
}

export const Logo = (props: LogoProps) => {
  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', padding: '12px', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center'}}>
        <div className="logo" style={{display: 'inline-block'}}>
          { props.logo }
        </div>
        <div>
          <div style={{fontSize: 30, lineHeight:1.2, marginLeft: '12px'}}>
            { props.name }
          </div>
          <div style={{lineHeight:'20px', textAlign: 'left', fontSize: '85%', marginLeft: '12px'}}>
            { props.address }
          </div>
        </div>
        {
          props.phone_number && (
            <div style={{lineHeight:'16px', textAlign: 'center', marginLeft: '12px'}}>
              { props.phone_number }
            </div>
          )
        }
        { props.extra && <div style={{position: 'absolute', right: '15%'}}>
          { props.extra }
        </div> }
      </div>
    </div>
  );
};

export default Logo;
