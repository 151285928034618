import React from 'react';
import { Layout, Row, Col } from 'antd';
const { Content } = Layout;

import { PageSectionProps } from '@bwb-buildings-mono/entities';
import { SectionTitle } from '../section-title/section-title';

import './section-side-by-side.scss';

/* eslint-disable-next-line */
export interface SectionSideBySideProps extends PageSectionProps {
  leftSide: any;
  rightSide: any;
}

export const SectionSideBySide = (props: SectionSideBySideProps) => {
  return (
    <Content>
      { props.title && <SectionTitle { ...props}/> }
      <div className="site-layout-content img-sections">
        <Row className="img-section">
          <Col className="img-sentence" xs={24} md={12}>
            <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
              { props.leftSide }
            </div>
          </Col>
          <Col className="img-images" style={{overflow: 'hidden'}} xs={24} md={12}>
            { props.rightSide }
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default SectionSideBySide;
