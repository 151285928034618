import React, { useState } from 'react';
import { Layout, Typography } from 'antd';
const { Title } = Typography;
const { Content } = Layout;

import { Section, PageSectionProps } from '@bwb-buildings-mono/entities';
import { SectionTextCarouselItem } from './section-text-carousel-item';

import './section-text-carousel.scss';

/* eslint-disable-next-line */
export interface SectionTextCarouselProps extends PageSectionProps {
  sections:Section[];
}

export const SectionTextCarousel = (props: SectionTextCarouselProps) => {
  const [showModal, setShowModal] = useState(null);
  return (
    <Content>
      <div ref={props.sectionRef} style={{height: '80px'}}></div>
      <div style={{padding: '12px', textAlign: 'center', marginTop:'72px'}}>
        <Title level={2}>{ props.title }</Title>
      </div>
      <div className="site-layout-content img-sections">
        {
          props.sections.map((section, i) => {
            return (
              <SectionTextCarouselItem i={i} key={i} {...section}/>
            )
          })
        }
      </div>
    </Content>
  );
};

export default SectionTextCarousel;
