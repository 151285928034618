import React, { useState } from 'react';
import { Affix, Col, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { PageSection } from '@bwb-buildings-mono/entities';

import { Logo } from '../logo/logo';
import './nav.scss';


export const NAV_ITEM = {
  UNITS: 'units',
  AMENITIES: 'amentities',
  FLOORPLANS: 'floorplans',
  CONTACT: 'contact'
};

/* eslint-disable-next-line */
export interface NavProps {
  currentStepIndex: number;
  onClick:((x:string)=>void);
  sections?: PageSection[];

  logo?:any;
  rightSide?:any;
  leftSide?: any;
  rightSideModal?:any;
}

export const Nav = (props: NavProps) => {
  const { currentStepIndex, onClick } = props;
  const [showNav, setShowNav] = useState(false);





  return (
    <React.Fragment>
      { showNav &&
        <div className="mobilenavbar">
          { props.logo &&
            <div>
              <Logo {...props.logo}/>
            </div>
          }
          <div style={{padding: '24px'}}>
            <h2>Navigation</h2>
            <div className="navbuttons">
              {
                (props.sections || []).map((section, i) => {
                  if(!section.nav){
                    return null;
                  }
                  return (
                    <div key={'nav-' + i} className={(currentStepIndex == i) ? 'active' : ''} onClick={() => {
                      onClick(section.label);
                      setShowNav(false);
                    }}>{ section.nav }</div>
                  );
                })
              }
            </div>
            <div className="navbuttons" style={{textAlign: 'center'}}>
              {
                props.rightSideModal || props.rightSide
              }
            </div>
          </div>
        </div>
      }
      <Affix>
        <div className="navbar">
          <Row>
            <Col xs={24} sm={0}>
              <div className="navbuttons">
                <div onClick={() => setShowNav(true) }><MenuOutlined /></div>
              </div>
            </Col>
          </Row>
          <Row style={{alignItems: 'center'}}>
            <Col xs={0} sm={0} md={4}>
            <div className="navbuttons">
                {
                  props.leftSide
                }
              </div>
            </Col>
            <Col xs={0} sm={24} md={16}>
              <div className="navbuttons">
                {
                  (props.sections || []).map((section, i) => {
                    if(!section.nav){
                      return null;
                    }
                    return (
                      <div key={'nav-' + i} className={(currentStepIndex == i) ? 'active' : ''} onClick={() => onClick(section.label) }>{ section.nav }</div>
                    );
                  })
                }
              </div>
            </Col>
            <Col xs={0} sm={0} md={4}>
              <div className="navbuttons">
                {
                  props.rightSide
                }
              </div>
            </Col>
          </Row>
        </div>
      </Affix>
    </React.Fragment>
  );
};

export default Nav;
