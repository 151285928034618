import React from 'react';
import { Section } from '@bwb-buildings-mono/entities';
import { Typography, Row, Col, Divider } from 'antd';
const { Text, Paragraph } = Typography;

export const UnitFeatureMiniSections: Array<Section> = [
    {
        sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
            <Paragraph><Text mark strong>Large floor plans</Text> with huge kitchens.</Paragraph>
            <Paragraph><Text mark strong>Full-size washer and dryer</Text> in every unit.</Paragraph>
            <Paragraph><Text mark strong>New stainless steel appliances</Text>.</Paragraph>
            <Paragraph>New waterproof wood flooring, new cabinets.</Paragraph>
            <Paragraph><Text mark strong>Granite counters</Text> throughout.</Paragraph>
            <Paragraph><Text mark strong>Tiled shower</Text> in the master bathroom.</Paragraph>
            <Paragraph>Unique wet bars with a sink in the living room.</Paragraph>
        </div>,
        sentenceMore: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
          <Row gutter={24}>
              <Col sm={24} md={12}>
                  <Divider>Our Apartments</Divider>
                  <Paragraph>All of the above and...</Paragraph>
                  <Paragraph>New carpet in bedroom areas</Paragraph>
                  <Paragraph>Patios and Balconies in most units</Paragraph>
                  <Paragraph>Central heat and air conditioning</Paragraph>
                  <Paragraph>Glass shower enclosures</Paragraph>
              </Col>
              <Col sm={24} md={12}>
                  <Divider>State-of-the-art Technology</Divider>
                  <Paragraph><Text mark strong>Dual pane windows</Text> with efficient low energy glazing</Paragraph>
                  <Paragraph>Separate water system and <Text mark strong>individual water heaters</Text> in each unit</Paragraph>
                  <Paragraph>Controlled access entry with RFID and intercom</Paragraph>
                  <Paragraph>Cameras at all entry and exits to building for added security</Paragraph>
                  <Paragraph>Car Chargers Available</Paragraph>
                  <Paragraph>Solar Powered Common Areas</Paragraph>
              </Col>
          </Row>
      </div>,
      images: [
        [
            {
                src: 'assets/pics/overview_1.jpeg',
                caption: '439 W Stocker St.',
                thumb: 'assets/thumbs/overview_1.jpeg'
            },
            {
                src: 'assets/pics/overview_2.jpeg',
                caption: '439 W Stocker St.',
                thumb: 'assets/thumbs/overview_2.jpeg'
            },
            {
                src: 'assets/pics/overview_5.jpeg',
                caption: '439 W Stocker St.',
                thumb: 'assets/thumbs/overview_5.jpeg'
            },
            {
                src: 'assets/pics/overview_6.jpeg',
                caption: '439 W Stocker St.',
                thumb: 'assets/thumbs/overview_6.jpeg'
            }
        ],
        {
            src: 'assets/pics/laundry_1.jpeg',
            caption: '439 W Stocker St.',
            thumb: 'assets/thumbs/laundry_1.jpeg'
        },
        {
          src: 'assets/pics/kitchen_2.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/kitchen_2.jpeg'
        },
        {
          src: 'assets/pics/closet_2.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/closet_2.jpeg'
        },
        ,
        
        {
          src: 'assets/pics/overview_7.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/overview_7.jpeg'
        },
        {
          src: 'assets/pics/kitchen_5.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/kitchen_5.jpeg'
        },
        {
          src: 'assets/pics/kitchen_4.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/kitchen_4.jpeg'
        },
        {
          src: 'assets/pics/kitchen_3.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/kitchen_3.jpeg'
        },
        {
          src: 'assets/pics/bathroom_2.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/bathroom_2.jpeg'
        },
        {
          src: 'assets/pics/shower_1.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/shower_1.jpeg'
        },
        {
          src: 'assets/pics/wetbar_1.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/wetbar_1.jpeg'
        },
        {
          src: 'assets/pics/wetbar_2.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/wetbar_2.jpeg'
        },
        {
          src: 'assets/pics/wetbar_3.jpeg',
          caption: '439 W Stocker St.',
          thumb: 'assets/thumbs/wetbar_3.jpeg'
        }
      ]
    }
  ];