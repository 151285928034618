import React from 'react';
import { Row, Col, Typography } from 'antd';
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

import { UnitFeatureMiniSections } from '../data/unit-features-mini';
import { AmenitiesSections } from '../data/amenities';
const { Paragraph } = Typography;

const PHONE_NUMBER = '(818)789-3981';
const EMAIL = 'leasing@439stocker.com';
const ADDRESS_NAME = '439 Stocker Apartments';
const ADDRESS_1 = '439 Stocker St.';
const ADDRESS_2 = 'Glendale, CA 91202';

const tours = [
  {
    name: '1BR Loft, 1 BA - ~905 SF',
    url: 'https://my.matterport.com/show/?model=cq75Z1X7Mnj&play=1',
  },
  {
    name: '2BR Loft, 2BA - ~1169 SF',
    url: 'https://my.matterport.com/show/?model=vAECDgPVYVu&play=1',
  },
  {
    name: '2BR, 2BA - ~1239 SF',
    url: 'https://my.matterport.com/show/?model=8Z1RqLqXTNR&play=1',
  },
];

export const AppData: ApplicationData = {
  footer: (
    <div>
      <div>
        <Row>
          <Col md={24} xs={0}>
            {ADDRESS_NAME} — {ADDRESS_1}, {ADDRESS_2} — {PHONE_NUMBER}
          </Col>
          <Col md={0} xs={24}>
            {ADDRESS_NAME}
            <br />
            {ADDRESS_1}
            <br />
            {ADDRESS_2}
            <br />
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '80%', marginTop: 24 }}>
        <div>
          {ADDRESS_NAME} is located in or around GLENDALE, BURBANK, GRANDVIEW,
          TOLUCA LAKE, EAGLE ROCK, ATWATER VILLAGE, LOS FELIZ
        </div>
        <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
    </div>
  ),
  sections: [
    {
      element: UI.Logo,
      props: {
        logo: (
          <img alt="logo" style={{ height: '50px' }} src="./assets/logo.jpg" />
        ),
        address: (
          <div style={{ lineHeight: 1 }}>
            <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
            <div style={{ fontSize: '80%' }}>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </div>
          </div>
        ),
      },
    },
    {
      isNav: true,
      element: UI.Nav,
      props: {
        logo: {
          logo: (
            <img
              alt="logo"
              style={{ height: '50px' }}
              src="./assets/logo.png"
            />
          ),
          address: (
            <div style={{ lineHeight: 1 }}>
              <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
              <div style={{ fontSize: '80%' }}>{PHONE_NUMBER}</div>
            </div>
          ),
        },
        rightSide: (
          <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
        ),
        rightSideModal: (
          <div>
            <Paragraph>
              <UI.VirtualToursButton tours={tours} />
            </Paragraph>
            <Paragraph>
              <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
            </Paragraph>
          </div>
        ),
      },
    },
    {
      element: UI.HeroImage,
      props: {
        image: './assets/web/hero.jpg',
      },
    },
    {
      element: UI.Tagline,
      props: {
        tagline: 'New, spacious, remodeled apartments in Glendale, CA.',
        undertag: (
          <div>
            <UI.VirtualToursButton tours={tours} />
            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
          </div>
        ),
      },
    },
    {
      element: UI.AboveFold,
      props: {
        images: [
          './assets/pics/overview_1.jpeg',
          './assets/pics/kitchen_1.jpeg',
          './assets/pics/bathroom_2.jpeg',
          './assets/pics/laundry_1.jpeg',
          './assets/pics/bedroom_3.jpeg',
        ],
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.UNITS,
      nav: 'Units',
      props: {
        title: 'About Our Apartments',
        sections: UnitFeatureMiniSections,
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.AMENITIES,
      nav: 'Amenities',
      props: {
        title: 'Community Amenities',
        sections: AmenitiesSections,
      },
    },
    {
      element: UI.SectionImages,
      label: UI.NAV_ITEM.FLOORPLANS,
      nav: 'Floorplans',
      props: {
        title: 'Floorplans',
        images: [
          './assets/floorplans/Unit106 - 1+1.png',
          './assets/floorplans/Unit109 - 2+2A.png',
          './assets/floorplans/Unit203 - 2+2B.png',
          './assets/floorplans/Unit204 - 2+2C.png',
          './assets/floorplans/Unit306 - Loft.png',
          './assets/floorplans/Unit309 - 2 + 2 Loft.jpg',
        ],
      },
    },
    {
      element: UI.SectionTitle,
      label: UI.NAV_ITEM.CONTACT,
      nav: 'Tour',
      props: {
        title: 'Tour',
      },
    },
    {
      element: UI.SectionSideBySide,
      props: {
        leftSide: (
          <div>
            <h3>Schedule a tour with our on-site manager:</h3>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>Call anytime!</div>
            </div>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>
                Email us what you are looking for and some times that work for
                you.
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <Paragraph>
                <UI.VirtualToursButton tours={tours} />
              </Paragraph>
              <Paragraph>
                <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
              </Paragraph>
            </div>
          </div>
        ),
        rightSide: (
          <iframe
            title="Property Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.32709914033!2d-118.26586018478203!3d34.16355378057589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c04870e70683%3A0x4bc2f15441ff8f4d!2s439%20W%20Stocker%20St%2C%20Glendale%2C%20CA%2091202!5e0!3m2!1sen!2sus!4v1579839169631!5m2!1sen!2sus"
            width="400"
            height="400"
            frameBorder={0}
            style={{ border: '1px solid #CCC' }}
          ></iframe>
        ),
      },
    },
  ],
};
