import React, { useState } from 'react';
import { Button, Modal } from 'antd';

/* eslint-disable-next-line */
export interface BookTourButtonProps {
  phone_number?: string;
  email?: string;
}

export const BookTourButton = (props: BookTourButtonProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  // const [activeTour, setActiveTour] = useState(0);

  return (
    <React.Fragment>
      <Button
        style={{ margin: '0px 12px' }}
        type="primary"
        size="large"
        onClick={() => setShowModal(true)}
      >
        Book A Tour
      </Button>
      <Modal
        centered
        title="Book A Tour"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={null}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <div
          style={{
            height: '100%',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3>Schedule a tour with our on-site manager</h3>
          <div>
            <h1 style={{ marginBottom: 0 }}>
              <a href={`tel:${props.phone_number}`}>{props.phone_number}</a>
            </h1>
            <div>Call anytime!</div>
          </div>
          <div style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 0 }}>
              <a href={`mailto:${props.email}`}>{props.email}</a>
            </h1>
            <div>
              Email us what you are looking for and some times that work for
              you.
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BookTourButton;
